<template>
  <div>
    <div v-if="!loading" class="app-content-page">
      <div v-if="!isMobile">
        <el-tabs tab-position="left">
          <el-tab-pane label="Kalendar">
            <div><app-calendar></app-calendar></div>
          </el-tab-pane>
          <el-tab-pane label="Aufgaben">
            <app-genehmigung></app-genehmigung>
          </el-tab-pane>
          <!--   <el-tab-pane label="Role">Role</el-tab-pane>
          <el-tab-pane label="Task">Task</el-tab-pane> -->
        </el-tabs>
        <!--   <el-row :gutter="5">
          <el-col :span="16" :offset="0"></el-col>
          <el-col :span="8" :offset="0"></el-col>
        </el-row> -->
      </div>
      <div v-else style="padding-top: 40px">
        <el-row>
          <el-col>
            <app-calendar></app-calendar>
          </el-col>
          <el-col></el-col>
          <app-genehmigung></app-genehmigung>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Calendar from '@/modules/calendar/components/calendar.vue';
import Genehmigung from '@/modules/home/components/genehmigung.vue';

export default {
  name: 'app-home-page',

  components: {
    [Calendar.name]: Calendar,
    [Genehmigung.name]: Genehmigung,
  },

  async created() {
    if (this.isMobile) {
      this.collapseMenu();
    }
    this.loading = true;

    this.loading = false;
  },

  mounted() {},

  data() {
    return {
      loading: false,
      projectsByFakultaet: {},
      projectsByStatus: {},
      projectsByFunding: {},
    };
  },

  computed: {
    ...mapGetters({
      isMobile: 'layout/isMobile',
    }),
  },

  methods: {
    ...mapActions({
      collapseMenu: 'layout/collapseMenu',
    }),
  },
};
</script>

<style scoped>
.el-col {
  margin-bottom: 16px;
}

.home-bottom-message {
  width: 100%;
  text-align: center;
  color: grey;
}

.cal {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
</style>
