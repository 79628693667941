<template>
  <div>
    <el-form
      label-position="top"
      :label-width="labelWidthForm"
      :model="model"
      :rules="rules"
      @submit.native.prevent="doSubmit"
      class="form"
      ref="form"
      v-if="model"
      :disabled="finished"
    >
      <el-form-item
        :label="fields.title.label"
        :prop="fields.title.name"
        :required="fields.title.required"
      >
        <el-col>
          <el-input type="textarea" v-model="model[fields.title.name]" />
        </el-col>
      </el-form-item>

      <el-form-item
        :label="fields.startDate.label"
        :prop="fields.startDate.name"
        :required="fields.startDate.required"
      >
        <el-col>
          <el-date-picker
            :readonly="isEditing"
            :format="elementUiDateFormat"
            placeholder
            v-model="model[fields.startDate.name]"
          ></el-date-picker>
        </el-col>
      </el-form-item>

      <el-form-item
        :label="fields.endDate.label"
        :prop="fields.endDate.name"
        :required="fields.endDate.required"
      >
        <el-col>
          <el-date-picker
            :readonly="isEditing"
            :format="elementUiDateFormat"
            placeholder
            v-model="model[fields.endDate.name]"
          ></el-date-picker>
        </el-col>
      </el-form-item>
      <el-form-item
        :prop="fields.finished.name"
        :required="fields.finished.required"
        v-if="isEditing"
      >
        <el-checkbox v-model="model[fields.finished.name]">
          {{ fields.finished.label }}
        </el-checkbox>
      </el-form-item>
      <el-form-item
        :prop="fields.url.name"
        :required="fields.url.required"
        v-if="model[fields.url.name]"
      >
        <el-col>
          <router-link :to="{ path: `${model[fields.url.name]}` }">
            <el-button type="text">
              <app-i18n code="common.openMitarbeiter"></app-i18n>
            </el-button>
          </router-link>
        </el-col>
      </el-form-item>
      <el-form-item>
        <div class="form-buttons">
          <el-button
            :disabled="saveLoading"
            @click="doSubmit"
            icon="el-icon-fa-floppy-o"
            type="primary"
          >
            <app-i18n code="common.save"></app-i18n>
          </el-button>

          <el-button
            :disabled="saveLoading"
            @click="doCancel"
            icon="el-icon-fa-close"
          >
            <app-i18n code="common.cancel"></app-i18n>
          </el-button>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { FormSchema } from '@/shared/form/form-schema';
import { CalendarModel } from '@/modules/calendar/calendar-model';

const { fields } = CalendarModel;
const formSchema = new FormSchema([
  fields.id,
  fields.title,
  fields.startDate,
  fields.endDate,
  fields.url,
  fields.finished,
]);

export default {
  name: 'app-calendar-item-form',

  props: ['isEditing', 'record', 'saveLoading', 'mitarbeiterId'],

  data() {
    return {
      rules: formSchema.rules(),
      model: null,
    };
  },

  mounted() {
    this.model = formSchema.initialValues(this.record || {});
  },

  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
      elementUiDateFormat: 'layout/elementUiDateFormat',
    }),

    fields() {
      return fields;
    },

    finished() {
      return this.record ? this.record.finished : false;
    },
  },

  methods: {
    doCancel() {
      this.$emit('cancel');
    },

    async doSubmit() {
      try {
        await this.$refs.form.validate();
      } catch (error) {
        return;
      }

      if (this.mitarbeiterId) {
        this.model.mitarbeiterId = this.mitarbeiterId;
      }

      const { id, ...values } = formSchema.cast(this.model);

      return this.$emit('submit', {
        id,
        values,
      });
    },
  },
};
</script>

<style></style>
