import { i18n } from '@/i18n';
import IdField from '@/shared/fields/id-field';
import { GenericModel } from '@/shared/model/generic-model';
import DateField from '@/shared/fields/date-field';
import StringField from '@/shared/fields/string-field';
import DateRangeField from '@/shared/fields/date-range-field';
import BooleanField from '@/shared/fields/boolean-field';

function label(name) {
  return i18n(`entities.calendar.fields.${name}`);
}

const fields = {
  id: new IdField('id', label('id')),
  startDate: new DateField('startDate', label('startDate')),
  endDate: new DateField('endDate', label('endDate')),
  title: new StringField('title', label('title')),
  url: new StringField('url', label('url')),
  dateRange: new DateRangeField('dateRange', label('dateRange')),
  finished: new BooleanField('finished', label('finished')),
};

export class CalendarModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
