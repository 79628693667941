<template>
  <div>
    <div>
      <el-collapse value="Filter" accordion>
        <el-collapse-item :title="i18n('entities.calendar.options.title')">
          <el-form
            :label-position="labelPosition"
            :label-width="labelWidthFilter"
            @submit.native.prevent="doFilter"
            class="filter"
            ref="form"
          >
            <el-row type="flex" class="row-bg" :gutter="20">
              <el-col :span="6">
                <el-form-item
                  :label="i18n('entities.calendar.options.displayPeriod')"
                >
                  <el-select
                    v-model="displayPeriodUom"
                    value-key="value"
                    placeholder
                  >
                    <el-option
                      v-for="item in Def_displayPeriodUom"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="5">
                <el-form-item
                  :label="i18n('entities.calendar.options.periodCount')"
                >
                  <el-select
                    v-model="displayPeriodCount"
                    value-key="value"
                    placeholder
                  >
                    <el-option
                      v-for="item in Def_periodCount"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>

              <el-col :span="6">
                <el-form-item
                  :label="i18n('entities.calendar.options.displayWeekNumbers')"
                >
                  <el-checkbox v-model="displayWeekNumbers"></el-checkbox>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex" class="row-bg" :gutter="20">
              <el-col :span="6"></el-col>
            </el-row>
          </el-form>
        </el-collapse-item>
      </el-collapse>
    </div>
    <div id="calendar">
      <div class="calendar-parent" v-loading="loading">
        <calendar-view
          :items="data"
          :show-date="showDate"
          :time-format-options="{
            hour: 'numeric',
            minute: '2-digit',
          }"
          :enable-drag-drop="true"
          :disable-past="disablePast"
          :disable-future="disableFuture"
          :show-times="showTimes"
          :date-classes="myDateClasses"
          :display-period-uom="displayPeriodUom"
          :display-period-count="displayPeriodCount"
          :starting-day-of-week="startingDayOfWeek"
          :class="themeClasses"
          locale="de"
          :period-changed-callback="periodChanged"
          :current-period-label="useTodayIcons ? 'icons' : ''"
          :displayWeekNumbers="displayWeekNumbers"
          :enable-date-selection="true"
          :selection-start="selectionStart"
          :selection-end="selectionEnd"
          @date-selection-start="setSelection"
          @date-selection="setSelection"
          @date-selection-finish="finishSelection"
          @drop-on-date="onDrop"
          @click-date="onClickDay"
          @click-item="onClickItem"
          ref="calendarEvents"
        >
          <template #header="{ headerProps }">
            <calendar-view-header
              slot="header"
              :header-props="headerProps"
              @input="setShowDate"
            />
          </template>
        </calendar-view>
        <app-calendar-form-modal
          :item="item"
          :visible="dialogVisible"
          @close="onModalClose"
          @success="onModalClose"
          v-if="dialogVisible"
        ></app-calendar-form-modal>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { getElementUILanguage } from '@/i18n';
import '../../../../node_modules/vue-simple-calendar/static/css/default.css';
import '../../../../node_modules/vue-simple-calendar/static/css/holidays-us.css';
import CalendarItemFormModal from '@/modules/calendar/components/calendar-item-form-modal.vue';
import { i18n } from '@/i18n';

import {
  CalendarView,
  CalendarViewHeader,
  CalendarMathMixin,
} from 'vue-simple-calendar';

export default {
  name: 'app-calendar',
  components: {
    CalendarView,
    CalendarViewHeader,
    [CalendarItemFormModal.name]: CalendarItemFormModal,
  },
  data() {
    return {
      dialogVisible: false,
      item: {},
      filter: {},
      Def_displayPeriodUom: [
        {
          value: 'week',
          label: 'Week',
        },
        {
          value: 'month',
          label: 'Month',
        },
        {
          value: 'year',
          label: 'Year',
        },
      ],
      Def_periodCount: [
        {
          value: 1,
          label: '1',
        },
        {
          value: 2,
          label: '2',
        },
        {
          value: 3,
          label: '3',
        },
      ],
      /* Show the current month, and give it some fake items to show */
      showDate: this.thisMonth(1),
      message: '',
      startingDayOfWeek: 1,
      disablePast: false,
      disableFuture: false,
      displayPeriodUom: 'month',
      displayPeriodCount: 1,
      displayWeekNumbers: true,
      showTimes: true,
      selectionStart: null,
      selectionEnd: null,
      newItemTitle: '',
      newItemStartDate: '',
      newItemEndDate: '',
      useDefaultTheme: true,
      useHolidayTheme: false,
      useTodayIcons: false,
    };
  },
  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthFilter: 'layout/labelWidthFilter',
      currentUser: 'auth/currentUser',
      isMobile: 'layout/isMobile',
      data: 'calendar/data',
      loading: 'calendar/loading',
    }),
    userLocale() {
      return getElementUILanguage();
      /* return CalendarMathMixin.methods.getDefaultBrowserLocale(); */
    },
    dayNames() {
      return CalendarMathMixin.methods.getFormattedWeekdayNames(
        this.userLocale,
        'long',
        0,
      );
    },
    themeClasses() {
      return {
        'theme-default': this.useDefaultTheme,
      };
    },
    myDateClasses() {
      // This was added to demonstrate the dateClasses prop. Note in particular that the
      // keys of the object are `yyyy-mm-dd` ISO date strings (not dates), and the values
      // for those keys are strings or string arrays. Keep in mind that your CSS to style these
      // may need to be fairly specific to make it override your theme's styles. See the
      // CSS at the bottom of this component to see how these are styled.
      const o = {};
      const theFirst = this.thisMonth(1);
      const ides = [2, 4, 6, 9].includes(theFirst.getMonth()) ? 15 : 12;
      const idesDate = this.thisMonth(ides);
      o[CalendarMathMixin.methods.isoYearMonthDay(idesDate)] = 'ides';
      o[CalendarMathMixin.methods.isoYearMonthDay(this.thisMonth(21))] = [
        'do-you-remember',
        'the-21st',
      ];
      return o;
    },
  },
  mounted() {
    this.setFilter();
    return this.doFilter();
  },

  methods: {
    ...mapActions({
      doReset: 'calendar/doReset',
      doFetch: 'calendar/doFetch',
    }),
    periodChanged() {
      this.setFilter();
      return this.doFilter();
    },
    thisMonth(d, h, m) {
      const t = new Date();
      return new Date(t.getFullYear(), t.getMonth(), d, h || 0, m || 0);
    },
    onClickDay(startDate) {
      this.item.startDate = startDate;
      this.item.endDate = startDate;
      this.dialogVisible = true;
    },
    onClickItem(item) {
      this.item.id = item.id;
      this.dialogVisible = true;
    },
    setShowDate(d) {
      this.message = `Changing calendar view to ${d.toLocaleDateString()}`;
      this.showDate = d;
      this.setFilter();
    },
    setSelection(dateRange) {
      this.selectionEnd = dateRange[1];
      this.selectionStart = dateRange[0];
      this.item.startDate = this.selectionStart;
      this.item.endDate = this.selectionEnd;
    },
    finishSelection(dateRange) {
      this.setSelection(dateRange);
      this.dialogVisible = true;
      this.message = `You selected: ${this.selectionStart.toLocaleDateString()} -${this.selectionEnd.toLocaleDateString()}`;
    },
    onDrop(item, date) {
      this.message = `You dropped ${item.id} on ${date.toLocaleDateString()}`;
      // Determine the delta between the old start date and the date chosen,
      // and apply that delta to both the start and end date to move the item.
      const eLength = CalendarMathMixin.methods.dayDiff(item.startDate, date);
      item.originalItem.startDate = CalendarMathMixin.methods.addDays(
        item.startDate,
        eLength,
      );
      item.originalItem.endDate = CalendarMathMixin.methods.addDays(
        item.endDate,
        eLength,
      );
    },
    clickTestAddItem() {
      this.items.push({
        startDate: this.newItemStartDate,
        endDate: this.newItemEndDate,
        title: this.newItemTitle,
        id: 'e' + Math.random().toString(36).substr(2, 10),
      });
      this.message = 'You added a calendar item!';
    },
    doOpenModal() {
      this.dialogVisible = true;
    },
    onModalClose() {
      this.dialogVisible = false;
      this.item = {};
    },
    async doFilter() {
      return this.doFetch({
        filter: this.filter,
      });
    },
    setFilter() {
      const periodStart = CalendarMathMixin.methods.beginningOfPeriod(
        this.showDate,
        this.displayPeriodUom,
        this.startingDayOfWeek,
      );

      const periodEnd = CalendarMathMixin.methods.addDays(
        CalendarMathMixin.methods.incrementPeriod(
          periodStart,
          this.displayPeriodUom,
          this.displayPeriodCount,
        ),
        -1,
      );

      const startDate = CalendarMathMixin.methods.beginningOfWeek(
        periodStart,
        this.startingDayOfWeek,
      );

      const endDate = CalendarMathMixin.methods.endOfWeek(
        periodEnd,
        this.startingDayOfWeek,
      );

      this.filter = {
        startDate,
        endDate,
      };
    },
    i18n(code) {
      return i18n(code);
    },
  },
};
</script>

<style scoped>
html,
body {
  height: 100%;
  margin: 0;
  background-color: #f7fcff;
}

#calendar {
  display: flex;
  flex-direction: row;
  font-family: Calibri, sans-serif;
  font-weight: 400;
  /*   width: 95vw; */
  min-width: 30rem;
  max-width: 100rem;
  min-height: 40rem;
  margin-top: 0;
  margin-bottom: 5px;
}

.calendar-controls {
  margin-right: 1rem;
  min-width: 14rem;
  max-width: 14rem;
}

.calendar-parent {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: hidden;
  max-height: 80vh;
  background-color: white;
}

/* For long calendars, ensure each week gets sufficient height. The body of the calendar will scroll if needed */
.cv-wrapper.period-month.periodCount-2 .cv-week,
.cv-wrapper.period-month.periodCount-3 .cv-week,
.cv-wrapper.period-year .cv-week {
  min-height: 6rem;
}

/* These styles are optional, to illustrate the flexbility of styling the calendar purely with CSS. */

/* Add some styling for items tagged with the "birthday" class */
.theme-default .cv-item.birthday {
  background-color: #e0f0e0;
  border-color: #d7e7d7;
}

.theme-default .cv-item.birthday::before {
  content: '\1F382'; /* Birthday cake */
  margin-right: 0.5em;
}

/* The following classes style the classes computed in myDateClasses and passed to the component's dateClasses prop. */
.theme-default .cv-day.ides {
  background-color: #ffe0e0;
}

.ides .cv-day-number::before {
  content: '++';
}

.cv-day.do-you-remember.the-21st .cv-day-number::after {
  content: '\1F30D\1F32C\1F525';
}
</style>
