<template>
  <div v-loading="loading">
    <div class="board-column">
      <div class="board-column-header">
        <slot name="title"> </slot>
      </div>
      <div class="board-column-content">
        <div v-for="item in items" :key="item.id" class="board-item">
          <router-link :to="`/mitarbeiter/${item.mitarbeiterId}/edit`">
            <p style="font-weight: bold;float: left;">{{ item.vollname }}</p>
            <p style="float: right;">
              {{ formatDate(item.date) }}
            </p>
            <div style="clear: both;"></div>
            <p>{{ item.action }}</p>
          </router-link>
        </div>
      </div>
    </div>

    <!--  <div class="gennehmigung-card">
      <el-card shadow="never">
        <div slot="header" class="clearfix">
          <span>{{ title }}</span>
        </div>
        <div v-for="item in items" :key="item.id" class="text item">
          <router-link :to="`/mitarbeiter/${item.mitarbeiterId}/edit`">
            <el-button type="text">{{ item.fullname }}</el-button>
          </router-link>
          <p>- {{ item.action }}</p>
        </div>
      </el-card>
    </div> -->
  </div>
</template>

<script>
import moment from 'moment';
export default {
  name: 'app-gennehmigung-card',
  props: ['title', 'items', 'loading'],
  methods: {
    formatDate(date) {
      return moment(date).format('DD.MM.YYYY');
    },
  },
};
</script>

<style scoped>
.board-column {
  /*   min-width: 300px; */
  min-height: 100px;
  color: #606266;
  height: auto;
  overflow: hidden;
  background: #f0f0f0;
  border-radius: 5px;
}

.board-item:focus,
.board-item:hover {
  color: rgb(107, 167, 228);
  border-color: #c6e2ff;
  background-color: #ecf5ff;
}

.board-column-header {
  height: 50px;
  line-height: 50px;
  overflow: hidden;
  padding: 0 20px;
  text-align: center;
  background: #e9e9e9;
  border-radius: 3px 3px 0 0;
}

.board-column-content {
  height: auto;
  overflow: hidden;
  border: 10px solid transparent;
  min-height: 60px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
}
.board-item {
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  /*  max-height: 100px;
  height: 70px; */
  font-size: 14px;
  overflow: hidden;
  margin: 5px 0;
  background-color: #fff;
  text-align: left;
  /*   line-height: 54px; */
  padding: 0px 10px;
  box-sizing: border-box;
  box-shadow: 0px 1px 3px 0 rgba(0, 0, 0, 0.2);
}
p {
  margin-top: 5px;
  margin-bottom: 5px;
}
</style>
