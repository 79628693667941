<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="20%"
      v-if="loaded"
    >
      <app-calendar-item-form
        :isEditing="isEditing"
        :record="record"
        :mitarbeiterId="mitarbeiterId"
        :saveLoading="saveLoading"
        @cancel="doCancel"
        @submit="doSubmit"
        v-if="loaded"
      />
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import CalendarForm from '@/modules/calendar/components/calendar-item-form.vue';
import { i18n } from '@/i18n';

export default {
  name: 'app-calendar-form-modal',

  props: ['item', 'visible', 'mitarbeiterId'],

  components: {
    [CalendarForm.name]: CalendarForm,
  },

  data() {
    return { record: null, loaded: false, isEditing: false };
  },

  async created() {
    if (this.item && this.item.id) {
      this.record = await this.doInit(this.item.id);
      this.isEditing = true;
    } else {
      this.record = this.item;
    }
    this.loaded = true;
  },

  computed: {
    ...mapGetters({
      initLoading: 'calendar/initLoading',
      saveLoading: 'calendar/saveLoading',
    }),

    dialogVisible: {
      get: function () {
        return this.visible;
      },

      set: function (value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },

    title() {
      let mode = this.isEditing ? (mode = 'edit') : (mode = 'new');
      return i18n(`entities.calendar.${mode}.title`);
    },
  },

  methods: {
    ...mapActions({
      doInit: 'calendar/doInit',
      doUpdate: 'calendar/doUpdate',
      doCreate: 'calendar/doCreate',
    }),

    async doSubmit(payload) {
      if (this.isEditing) {
        await this.doUpdate(payload);
      } else {
        await this.doCreate(payload.values);
      }
      this.dialogVisible = false;
    },

    doCancel() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style></style>
