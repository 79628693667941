<template>
  <el-row :gutter="5">
    <p style="text-align: center;">
      <app-i18n code="entities.gennehmigung.name"></app-i18n>
    </p>
    <el-col :span="6">
      <app-gennehmigung-card
        :loading="loadingPendingVertraege"
        :items="vertaege"
      >
        <template v-slot:title>
          <app-i18n code="entities.gennehmigung.headers.vertrag"></app-i18n>
        </template>
      </app-gennehmigung-card>
    </el-col>
    <el-col :span="6">
      <app-gennehmigung-card
        :loading="loadingPendingVertragsaenedrung"
        :items="vertragsaenderung"
      >
        <template v-slot:title>
          <app-i18n
            code="entities.gennehmigung.headers.vertragsaenderung"
          ></app-i18n>
        </template>
      </app-gennehmigung-card>
    </el-col>
    <el-col :span="6">
      <app-gennehmigung-card
        :loading="loadingPendingVertragsergaenzung"
        :items="vertragsergaenzung"
      >
        <template v-slot:title>
          <app-i18n
            code="entities.gennehmigung.headers.vertragsergaenzung"
          ></app-i18n>
        </template>
      </app-gennehmigung-card>
    </el-col>
    <el-col :span="6">
      <app-gennehmigung-card
        :loading="loadingPendingBeendigunge"
        :items="beendigungen"
      >
        <template v-slot:title>
          <app-i18n code="entities.gennehmigung.headers.beendigung"></app-i18n>
        </template>
      </app-gennehmigung-card>
    </el-col>
  </el-row>
</template>

<script>
import GenehmigungCard from '@/modules/home/components/genehmigung-card.vue';
import { VertragService } from '@/modules/vertrag/vertrag-service';
import { VertragsaenderungService } from '@/modules/vertragsaenderung/vertragsaenderung-service';
import { VertragsergaenzungService } from '@/modules/vertragsergaenzung/vertragsergaenzung-service';
import { BeendigungService } from '@/modules/beendigung/beendigung-service';
import Errors from '@/shared/error/errors';

export default {
  name: 'app-genehmigung',
  components: {
    [GenehmigungCard.name]: GenehmigungCard,
  },
  data() {
    return {
      vertaege: null,
      vertragsaenderung: null,
      vertragsergaenzung: null,
      beendigungen: null,
      loadingPendingVertraege: false,
      loadingPendingVertragsaenedrung: false,
      loadingPendingVertragsergaenzung: false,
      loadingPendingBeendigunge: false,
    };
  },
  async created() {
    this.doFetchPendingVertraege();
    this.doFetchPendingVertragsaenderung();
    this.doFetchPendingVertragsergaenzung();
    this.doFetchPendingBeendigungen();
  },

  methods: {
    async doFetchPendingVertraege() {
      try {
        this.loadingPendingVertraege = true;
        this.vertaege = await VertragService.listPending();
      } catch (error) {
        Errors.handle(error);
      } finally {
        this.loadingPendingVertraege = false;
      }
    },
    async doFetchPendingVertragsaenderung() {
      try {
        this.loadingPendingVertraege = true;
        this.vertragsaenderung = await VertragsaenderungService.listPending();
      } catch (error) {
        Errors.handle(error);
      } finally {
        this.loadingPendingVertraege = false;
      }
    },
    async doFetchPendingVertragsergaenzung() {
      try {
        this.loadingPendingVertraege = true;
        this.vertragsergaenzung = await VertragsergaenzungService.listPending();
      } catch (error) {
        Errors.handle(error);
      } finally {
        this.loadingPendingVertraege = false;
      }
    },
    async doFetchPendingBeendigungen() {
      try {
        this.loadingPendingBeendigunge = true;
        this.beendigungen = await BeendigungService.listPending();
      } catch (error) {
        Errors.handle(error);
      } finally {
        this.loadingPendingBeendigunge = false;
      }
    },
  },
};
</script>

<style></style>
